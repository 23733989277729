import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import YmapPlugin from 'vue-yandex-maps'

const settings = {
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',
}

Vue.config.productionTip = false
Vue.use(YmapPlugin, settings)

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
